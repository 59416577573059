import 'firebase/storage';

import { Link } from 'gatsby';
import { auth, googleProvider, useAuth } from 'gatsby-theme-firebase';
import React, { useEffect } from 'react';
import { Button, Flex, Text } from 'rebass/styled-components';

import Loadable from '@loadable/component';

import Layout from '../components/layout';
import Logo from '../components/Logo';
import SEO from '../components/seo';

const LoadableConsoleEvents = Loadable(() =>
  import('../components/ConsoleEvents')
);

const ConsolePage = () => {
  const { isLoading, isLoggedIn, profile } = useAuth();

  const handleGoogleLogin = async () => {
    try {
      await auth.signInWithPopup(googleProvider());
    } catch (err) {
      console.error('Authentication Error: ', err);
    }
  };

  const handleLogout = async () => {
    await auth.signOut();
  };

  useEffect(() => {
    if (!profile) {
      return;
    }

    const init = async () => {
      const token = await profile.getIdTokenResult();
      if (!token.claims.admin) {
        auth.signOut();
      }
    };

    init();
  }, [profile]);

  if (isLoading) {
    return null;
  }

  if (!isLoggedIn) {
    return (
      <Layout dark>
        <Flex
          flex={1}
          color="#000"
          p={3}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Text mb={3} color="yellow" fontSize={3} fontWeight={600}>
            Console
          </Text>
          <Button onClick={handleGoogleLogin} variant="outline" color="yellow">
            Sign in with Google
          </Button>
        </Flex>
      </Layout>
    );
  }

  return (
    <Layout>
      <SEO title="Console" />
      <Flex mx={3} py={3} alignItems="center">
        <Flex flex={1}>
          <Link to="/console">
            <Logo />
          </Link>
        </Flex>
        <Flex>
          <Button
            onClick={handleLogout}
            variant="outline"
            color="yellow"
            sx={{ cursor: 'pointer' }}
          >
            Log out
          </Button>
        </Flex>
      </Flex>
      <Flex flex={1} flexDirection="column" mx={3} sx={{ height: '100%' }}>
        <LoadableConsoleEvents />
      </Flex>
    </Layout>
  );
};

export default ConsolePage;
